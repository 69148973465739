
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function onLoad2(e) {
                    e.target.setAttribute('loaded', '');
                }
                function onLoad3(e) {
                    e.target.setAttribute('loaded', '');
                }
                return _createElement('div', {
                    'className': '#product-card @align:mobile-center shadow-2 @image-padding',
                    'style': { maxLines: '3' }
                }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'stretched-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': '#product-card-badges @position:right' }, this.on_sale ? _createElement('div', {
                    'className': '#product-card-badge @type:sale',
                    'key': '261'
                }, '\n      ', Math.ceil((1 - this.price / this.compare_at_price) * 100), '% off\n    ') : null, this.out_of_stock ? _createElement('div', {
                    'className': '#product-card-badge @type:sold-out',
                    'key': '413'
                }, 'Sold out') : null, this.New ? _createElement('div', {
                    'className': '#product-card-badge @type:new',
                    'key': '506'
                }, 'New') : null, this.Closeout ? _createElement('div', {
                    'className': '#product-card-badge @type:closeout',
                    'key': '580'
                }, 'Closeout') : null), _createElement('div', { 'className': '#product-card-media' }, _createElement('div', {
                    'className': '#media @type:ar @type-mobile:ar @border-radius:base',
                    'style': {
                        imageFit: 'contain',
                        arPadding: '100%'
                    }
                }, _createElement('div', { 'className': '#media-image-wrapper' }, _createElement('img', {
                    'className': '#media-image @animate-loading',
                    'alt': this.removeHTML(this.title),
                    'width': '500',
                    'height': '500',
                    'loading': 'lazy',
                    'onLoad': onLoad2.bind(this),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError
                }), _createElement('div', { 'className': '#media-image-loading' }))), _createElement('div', { 'className': '#product-card-media-alt load-hidden' }, _createElement('div', {
                    'className': '#media @type:ar @type-mobile:ar @border-radius:base',
                    'style': {
                        imageFit: 'contain',
                        arPadding: '100%'
                    }
                }, _createElement('div', { 'className': '#media-image-wrapper' }, _createElement('img', {
                    'className': '#media-image @animate-loading',
                    'alt': this.removeHTML(this.title),
                    'width': '500',
                    'height': '500',
                    'loading': 'lazy',
                    'onLoad': onLoad3.bind(this),
                    'src': this.resizeImage(this.image2),
                    'onError': e => this.onImageError(e, 'image2')
                }))))), _createElement('div', { 'className': '#product-card-caption @offset-top' }, _createElement('h3', mergeProps({ 'className': '#product-card-title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': '#product-card-price' }, _createElement('dl', { 'className': '#price' }, this.price_varies ? _createElement('span', {
                    'className': 'price_varies',
                    'key': '2019'
                }, 'From: ') : null, _createElement('div', { 'className': '#price-item @regular ' + (this.on_sale ? '@on-sale' : '') }, _createElement('dt', { 'className': '#price-item-heading visually-hidden' }, this.on_sale ? 'Sale price' : 'Price'), _createElement('dd', { 'className': '#price-item-value' }, _createElement('span', { 'className': '#price-value' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price))))), this.on_sale ? _createElement('div', {
                    'className': '#price-item @compare',
                    'key': '2517'
                }, _createElement('dt', { 'className': '#price-item-heading visually-hidden' }, 'Regular price'), _createElement('dd', { 'className': '#price-item-value' }, _createElement('span', { 'className': '#price-value' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))))) : null)), _createElement('div', { 'className': '#product-card-express-button @align:center' }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'cm_product-item__buttons-form',
                    'id': this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 || this.product_type?.includes('OEM Schematic') ? [_createElement('a', {
                        'className': '#button @display-style:normal @size:body @shadow heading-font',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '30861'
                    }, _createElement('span', { 'className': '#product-card-express-button-text' }, '\n              ', this.product_type?.includes('OEM Schematic') ? 'Show Details' : this.out_of_stock ? 'Sold out' : 'Select options', '\n            '))] : null, this.variant_ids.length === 1 && !this.product_type?.includes('OEM Schematic') ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '36281'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '36283'
                    }),
                    _createElement('button', {
                        'className': '#button @display-style:normal @size:body @shadow heading-font',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'disabled': this.out_of_stock,
                        'key': '36285'
                    }, _createElement('span', { 'className': '#product-card-express-button-text' }, '\n              ', this.out_of_stock ? 'Sold out' : 'Add to cart', '\n            '))
                ] : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []